<template>
  <section>
    <v-img :src="`/img/icons/diving/${icon}.svg`" style="max-width: 200px; max-height: 200px; height: 100%; width: 100%;"></v-img>
  </section>
</template>

<script>
export default {
  name: 'divingIcon',
  props: {
    icon: {
      type: String
    }
  }
}
</script>

<style scoped>

</style>
